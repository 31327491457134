import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import { get } from 'lodash';

import Auxiliary from "util/Auxiliary";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import SideBarCrud from "../../components/SideBarCrud";
import { getAllPermission } from "../../urls";
import { Redirect } from "react-router";


class SidebarContent extends Component {
  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  state = {
    allPermissions: [],

  };
  componentDidMount() {
    getAllPermission().then(response => {
      this.setState({
        allPermissions: response.data.rows.map((value) => value.value)
      });
    });
  }
  getViewAccess(viewName) {
    const permission = this.state.allPermissions.find((permission) => permission.view === viewName);
    const canView = get(permission, "canView", []);
    const canAdd = get(permission, "canAdd", []);
    return ({ canView: canView, canAdd: canAdd })
  }
  render() {
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    if (!this.props.user) { return <Redirect to={'/signin'} /> }
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content customestyle">
          <div
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle
            )}`}
          >
            {/* <UserProfile /> */}
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              {(this.getViewAccess('dashboard').canView).includes(this.props.user.type) ?
                <Menu.Item
                  style={{ padding: "0 !important" }}
                  className="ant-single-menu ant-menu-submenu ant-menu-submenu-inline sidebar-sub-menu"
                  key=""
                >
                  <Link to={`/dashboard`}>
                    <div className="ant-menu-submenu-title">
                      <Icon className="icon-style" type="pie-chart" />
                      <span>Dashboard</span>
                    </div>
                  </Link>
                </Menu.Item>
                : null}
              {/* <SideBarCrud
                viewName="dashboard"
                textName="dashboard"
                iconName="up"
                key=""
                {...this.props}/> */}
              {(this.getViewAccess('rooms').canView).includes(this.props.user.type) ||
                (this.getViewAccess('rooms').canAdd).includes(this.props.user.type) ?
                <SideBarCrud
                  viewName="rooms"
                  textName="rooms"
                  iconName="border"
                  key=""
                  canAccess={this.getViewAccess('rooms').canView}
                  canAdd={this.getViewAccess('rooms').canAdd}
                  {...this.props}
                /> : null}
              {(this.getViewAccess('customers').canView).includes(this.props.user.type) ||
                (this.getViewAccess('customers').canAdd).includes(this.props.user.type) ?
                <SideBarCrud
                  viewName="customers"
                  textName="customers"
                  iconName="team"
                  key=""
                  canAccess={this.getViewAccess('customers').canView}
                  canAdd={this.getViewAccess('customers').canAdd}
                  {...this.props}
                /> : null}
              <SideBarCrud
                viewName="permissions"
                textName="permissions"
                iconName="lock"
                key=""
                canAdd={["1"]}
                canAccess={["1"]}
                {...this.props}
              />
              {(this.getViewAccess('staff').canView).includes(this.props.user.type) ||
                (this.getViewAccess('staff').canAdd).includes(this.props.user.type) ?
                <SideBarCrud
                  viewName="staff"
                  textName="staff"
                  iconName="user"
                  key=""
                  canAccess={this.getViewAccess('staff').canView}
                  canAdd={this.getViewAccess('staff').canAdd}
                  {...this.props}
                /> : null}
              {(this.getViewAccess('category').canView).includes(this.props.user.type) ||
                (this.getViewAccess('category').canAdd).includes(this.props.user.type) ?
                <SideBarCrud
                  viewName="category"
                  textName="category"
                  iconName="container"
                  key=""
                  canAccess={this.getViewAccess('category').canView}
                  canAdd={this.getViewAccess('category').canAdd}
                  {...this.props}
                /> : null}
              {(this.getViewAccess('sub-category').canView).includes(this.props.user.type) ||
                (this.getViewAccess('sub-category').canAdd).includes(this.props.user.type) ?
                <SideBarCrud
                  viewName="sub-category"
                  textName="sub-category"
                  iconName="project"
                  key=""
                  canAccess={this.getViewAccess('sub-category').canView}
                  canAdd={this.getViewAccess('sub-category').canAdd}
                  {...this.props}
                /> : null}
              {(this.getViewAccess('branches').canView).includes(this.props.user.type) ||
                (this.getViewAccess('branches').canAdd).includes(this.props.user.type) ?
                <SideBarCrud
                  viewName="branches"
                  textName="branches"
                  iconName="branches"
                  key=""
                  canAccess={this.getViewAccess('branches').canView}
                  canAdd={this.getViewAccess('branches').canAdd}
                  {...this.props}
                /> : null}
              {(this.getViewAccess('promotions').canView).includes(this.props.user.type) ||
                (this.getViewAccess('promotions').canAdd).includes(this.props.user.type) ?
                <SideBarCrud
                  viewName="promotions"
                  textName="promotions"
                  iconName="percentage"
                  key=""
                  canAccess={this.getViewAccess('promotions').canView}
                  canAdd={this.getViewAccess('promotions').canAdd}
                  {...this.props}
                /> : null}
              {(this.getViewAccess('products').canView).includes(this.props.user.type) ||
                (this.getViewAccess('products').canAdd).includes(this.props.user.type) ?
                <SideBarCrud
                  viewName="products"
                  textName="products"
                  iconName="shopping-cart"
                  key=""
                  canAccess={this.getViewAccess('products').canView}
                  canAdd={this.getViewAccess('products').canAdd}
                  {...this.props}
                /> : null}
              {(this.getViewAccess('add-on-products').canView).includes(this.props.user.type) ||
                (this.getViewAccess('add-on-products').canAdd).includes(this.props.user.type) ?
                <SideBarCrud
                  viewName="add-on-products"
                  textName="add-on-products"
                  iconName="plus"
                  key=""
                  canAccess={this.getViewAccess('add-on-products').canView}
                  canAdd={this.getViewAccess('add-on-products').canAdd}
                  {...this.props}
                /> : null}
              {(this.getViewAccess('rooms').canView).includes(this.props.user.type) ||
                (this.getViewAccess('rooms').canAdd).includes(this.props.user.type) ?
                <SideBarCrud
                  viewName="sessions"
                  textName="session"
                  iconName="clock-circle"
                  key=""
                  canAccess={this.getViewAccess('sessions').canView}
                  canAdd={this.getViewAccess('sessions').canAdd}
                  {...this.props}
                /> : null}
              {(this.getViewAccess('memberships').canView).includes(this.props.user.type) ||
                (this.getViewAccess('memberships').canAdd).includes(this.props.user.type) ?
                <SideBarCrud
                  viewName="memberships"
                  textName="memberships"
                  iconName="user-add"
                  key=""
                  canAccess={this.getViewAccess('memberships').canView}
                  canAdd={this.getViewAccess('memberships').canAdd}
                  {...this.props}
                /> : null}
              {(this.getViewAccess('reports').canView).includes(this.props.user.type) ||
                (this.getViewAccess('reports').canAdd).includes(this.props.user.type) ?
                <SideBarCrud
                  viewName="reports"
                  textName="reports"
                  iconName="file"
                  key=""
                  canAccess={this.getViewAccess('reports').canView}
                  canAdd={this.getViewAccess('reports').canAdd}
                  {...this.props}
                />
                : null}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { user } = auth;
  return { navStyle, themeType, locale, pathname, user };
};
export default connect(mapStateToProps)(SidebarContent);
