import axios from "axios";

export const DEVELOPMENT = process.env.REACT_APP_DOMAIN_URL
  ? process.env.REACT_APP_DOMAIN_URL
  : // : `https://live.airzone.cleversell.io/`;
    `http://139.162.249.124:5984/`;
export const DEVELOPMENT_LOGIN = `http://139.162.249.124:5984/`;
//NOTE: All Timestamps are in unix (string) format

//Addons
export const getAddonsUrl = () =>
  axios.get(
    `${DEVELOPMENT}add-on-products/_design/allAddonProducts/_view/new-view`
  );

//Bookings
export const getBookingsOfDateUrl = (branchId, roomId, startDate, endDate) =>
  axios.get(
    `${DEVELOPMENT}bookings/_design/search-date/_view/new-view?startkey=[%22${branchId}%22,%22${roomId}%22,%22${startDate}%22]&endkey=[%22${branchId}%22,%22${roomId}%22,%22${endDate}%22]`
  );

export const getSerialUrl = () =>
  axios.get(`${DEVELOPMENT}bookings/_design/calcMaxID/_view/new-view`);
export const getBookingsOfAllRoomUrl = (branchId, startDate, endDate) =>
  axios.get(
    `${DEVELOPMENT}bookings/_design/search-date/_view/all-bookings?startkey=[%22${branchId}%22,%22${startDate}%22]&endkey=[%22${branchId}%22,%22${endDate}%22]`
  );
export const getOrdersOfRangeUrl = (startDate, endDate) =>
  axios.get(
    `${DEVELOPMENT}bookings/_design/orders/_view/new-view?startkey=%22${startDate}%22&endkey=%22${endDate}%22`
  );

//Branches
export const getBranchesUrl = () =>
  axios.get(`${DEVELOPMENT}branches/_design/getBranches/_view/new-view`);

//Products
export const getProductsOfRoomUrl = room_id =>
  axios.get(
    `${DEVELOPMENT}products/_design/getProductsOfRoom/_view/new-view?startkey=%22${room_id}%22&endkey=%22${room_id}%22`
  );
//getAllProducts
export const getProductsUrl = () =>
  axios.get(`${DEVELOPMENT}products/_design/getAllProducts/_view/new-view`);
//Rooms
// export const getRoomsUrl = () => axios.get(`${DEVELOPMENT}rooms/_design/allRooms/_view/new-view?startkey=%22a3be8760-9b03-11e8-ab05-3f7e17042767%22&endkey=%22a3be8760-9b03-11e8-ab05-3f7e17042767%22`);
export const getRoomsUrl = () =>
  axios.get(`${DEVELOPMENT}rooms/_design/allRooms/_view/new-view?`);
//rooms by Branch
export const getRoomsOfBranchUrl = branchId =>
  axios.get(
    `${DEVELOPMENT}rooms/_design/allRooms/_view/new-view?startkey=%22${branchId}%22&endkey=%22${branchId}%22`
  );

//Login (Fetches the staff if exists)
export const getLoginUrl = (branchId, staffId, pin) =>
  axios.get(
    `${DEVELOPMENT}staff/_design/login/_view/new-view?key=[%22${branchId}%22,%22${staffId}%22,%22${pin}%22]`
  );
//Get All Staff
export const getStaffUrl = () =>
  axios.get(`${DEVELOPMENT}staff/_design/getAllStaff/_view/new-view`);
//Promocodes
export const getPromoCodesUrl = () =>
  axios.get(`${DEVELOPMENT}promo/_design/branchPromos/_view/new-view`);

//Dashboard
export const getDashboardUrl = (startTimestamp, endTimestamp) =>
  axios.get(
    `${DEVELOPMENT}bookings/_design/allRoomsTotalPayments/_view/new-view?startkey=%22${startTimestamp}%22&endkey=%22${endTimestamp}%22`
  );

//Customers
export const getCustomersUrl = () =>
  axios.get(`${DEVELOPMENT}users/_all_docs?include_docs=true`);

//Add Any Object
export const addObject = (objectType, id, body) =>
  axios.put(`${DEVELOPMENT}${objectType}/${id}`, body);
//Login
export const login = (branchId, staffId, pin) =>
  axios.get(
    `${DEVELOPMENT}staff/_design/login/_view/new-view?key=[${branchId},${staffId}, ${pin}]`
  );
//Sessions
export const getSessionUrl = () =>
  // sessions/_design%2FgetAllSessions%2F_view%2Fnew-view
  axios.get(`${DEVELOPMENT}sessions/_design/getAllSessions/_view/new-view`);
//Memberships
export const getMembershipUrl = () =>
  axios.get(`${DEVELOPMENT}memberships/_all_docs?include_docs=true`);
//Reports by Branch
export const getTotalPayments = (branchId, startTimestamp, endTimestamp) => {
  return axios.get(`${DEVELOPMENT}bookings/_design/allRoomsTotalPaymentsByBranch/_view/new-view?startkey=[%22${branchId}%22,%22${startTimestamp}%22]&endkey=[%22${branchId}%22,%22${endTimestamp}%22]
`);
};

//All reports
export const getAllTotalPayments = (startTimestamp, endTimestamp) => {
  return axios.get(`${DEVELOPMENT}bookings/_design/allRoomsTotalPayments/_view/new-view?startkey=%22${startTimestamp}%22&endkey=%22${endTimestamp}%22
  `);
};
//Vr Reports
export const getVrReports = (startTimestamp, endTimestamp) => {
  return axios.get(`${DEVELOPMENT}bookings/_design/VRpayments/_view/new-view?startkey=%22${startTimestamp}%22&endkey=%22${endTimestamp}%22
   `);
};
//Permission

export const getAllPermission = () => {
  return axios.get(`${DEVELOPMENT}permissions/_design/getAllPermissions/_view/allPermissions
   `);
};
//Categories
export const getAllCategories = () => {
  return axios.get(`${DEVELOPMENT}categories/_design/allCategories/_view/allCategories
   `);
};
//SubCategories
export const getSubCategories = () => {
  return axios.get(`${DEVELOPMENT}sub-categories/_design/getSubCategories/_view/getSubCategories
   `);
};
//stockAddon Report

export const stockAddonReport = (startDate, endDate) => {
  return axios.get(
    `${DEVELOPMENT}bookings/_design/allStockNoVR/_view/new-view?startkey=%22${startDate}%22&endkey=%22${endDate}%22`
  );
};
//No Vr Reports by Branch
export const getTotalNoVrPayments = (
  branchId,
  startTimestamp,
  endTimestamp
) => {
  return axios.get(`${DEVELOPMENT}bookings/_design/allRoomsTotalPaymentsByBranch/_view/no-vr?startkey=[%22${branchId}%22,%22${startTimestamp}%22]&endkey=[%22${branchId}%22,%22${endTimestamp}%22]
`);
};
//No Vr all-Reports

export const getAllTotalNoVrPayments = (startTimestamp, endTimestamp) => {
  return axios.get(`${DEVELOPMENT}/bookings/_design/allRoomsTotalPayments/_view/no-vr?startkey=%22${startTimestamp}%22&endkey=%22${endTimestamp}%22
`);
};
